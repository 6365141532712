.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.error-message {
    color: red;
}

.aydinlatma-metni-container {
    font-family: 'Arial, sans-serif';
    line-height: 1.6;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f8f8f8;
    color: #333;
}

.aydinlatma-metni-container h1 {
    color: #1e409f;
}

.aydinlatma-metni-container h1,
.aydinlatma-metni-container h2,
.aydinlatma-metni-container h3,
.aydinlatma-metni-container h4 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.aydinlatma-metni-container .contact {
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
}
.aydinlatma-metni-container p {
    text-align: justify;
    margin-bottom: 15px;
}

